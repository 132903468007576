import React from "react"
import { graphql } from "gatsby"
import {
  Helmet,
  Theme,
  ReturnToTop,
  ResourcesTile,
  IconSheets,
  IconClipboard,
  IconLegal,
  Tile,
  ReadMoreLink,
  Section,
  Container,
  Row,
  Box,
  Heading,
  ContactUsPhoneLink,
  ContactUsEmailLink,
  IconPhone,
  Link,
  IconAt,
  IconClock,
  QuickNavigation,
  Banner,
  Layout,
  PreStyledComponents
} from "@life-without-barriers/react-components"
import styled from "styled-components"
import { Site } from "@life-without-barriers/gatsby-common"

const { IconWrapper } = PreStyledComponents

const { corporateThemeFull } = Theme

const SpacedSpan = styled.span`
  display: inline-block;
  min-width: 100px;
`

interface PolicyDocument {
  policyDocument?: {
    filename: string
    title: string
    url: string
  }
  title: string
  category: string
}

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    policyDocuments: {
      edges: [
        {
          node: PolicyDocument
        }
      ]
    }
  }
}

const PolicyCentrePage = ({
  location,
  data: { site, policyDocuments }
}: Props) => {
  const getPolicyDocumentsForCategory = (category: string) =>
    policyDocuments.edges
      .map(({ node }) => node)
      .filter(
        (contentItem) =>
          contentItem.policyDocument &&
          contentItem.policyDocument.url &&
          contentItem.category === category
      )
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((item) => ({
        name: item.policyDocument?.title ?? "",
        reference: item.policyDocument?.url ?? ""
      }))

  const navItems = [
    { title: "Policy statements", navigateTo: "#policy-statements" },
    { title: "Policy guidelines", navigateTo: "#policy-guidelines" },
    {
      title: "Processes and supporting documentation",
      navigateTo: "#processes-and-supporting-documentation"
    }
  ]
  return (
    <>
      <Helmet
        title={`Policy Centre | ${site.siteMetadata.title}`}
        description="Find and download policy statements and guidelines from Life Without Barriers"
        siteUrl={site.siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={corporateThemeFull}>
        <Banner title="Policy Centre" />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>Welcome to the policy centre</Heading>
              <p>
                At Life Without Barriers (LWB), our goal is to partner with
                people and change lives for the better. We want you to be
                informed about what we do, how we do it and why we do it, so we
                can work together to best deliver your supports.
              </p>
              <div id="quick-navigation" className="mt4">
                <QuickNavigation header="Quick navigation" items={navItems} />
              </div>
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l">
              <Tile ribbon>
                <div className="ma3 ma4-ns pv3-l">
                  <Heading
                    size={3}
                    className="color-lwb-theme-medium"
                    topMargin="none"
                  >
                    Contact us
                  </Heading>
                  <p className="copy mt3 mb4">
                    Couldn’t find the policy you were looking for? Contact us.
                  </p>
                  <ContactUsPhoneLink phoneNumber="1800935483" />
                  <ContactUsEmailLink
                    text="yourLWB@lwb.org.au"
                    link="mailto:yourLWB@lwb.org.au"
                  />
                </div>
                <a id="policy-statements" />
              </Tile>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>Policy Statements</Heading>
                <p>
                  Our Policy Statements describe our core purpose, our values,
                  and the principles we are committed to. Our statements mean
                  you are informed about why we at Life Without Barriers will
                  always try our best for you.
                </p>
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Resources"
                  icon={
                    <IconLegal height="25" color={corporateThemeFull.medium} />
                  }
                  resources={getPolicyDocumentsForCategory("Policy Statement")}
                />
                <div className="pt5_5 pt5-ns">
                  <ReturnToTop targetId="quick-navigation" />
                </div>
                <a id="policy-guidelines" />
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="relative">
            <Box className="layout-readable">
              <Heading size={2}>Policy Guidelines</Heading>
              <p>
                You can find out more detail about the principles that guide our
                work within our Policy Guidelines. Our guidelines describe what
                we do, how we do it, and who is responsible. You can find out
                more about how we ensure that we are delivering quality services
                to you here:
              </p>
              <ResourcesTile
                className="mt4 mt5-ns lwb-shadow w-100"
                name="Resources"
                icon={
                  <IconSheets height="25" color={corporateThemeFull.medium} />
                }
                resources={getPolicyDocumentsForCategory("Policy Guideline")}
              />
              <div className="pt5_5 pt5-ns">
                <ReturnToTop targetId="quick-navigation" />
              </div>
              <a id="processes-and-supporting-documentation" />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>Processes and supporting documents</Heading>
                <p>
                  We work in ways to ensure we can partner with people to change
                  lives for the better. Our Procedures and supporting documents
                  provide clear instructions and resources for specific tasks –
                  these include procedures, forms, and guides. You can find out
                  more about how we work with you here:
                </p>
                <ResourcesTile
                  className="mt4 mt5-ns lwb-shadow w-100"
                  name="Resources"
                  icon={
                    <IconClipboard
                      height="25"
                      color={corporateThemeFull.medium}
                    />
                  }
                  resources={getPolicyDocumentsForCategory(
                    "Procedure and Support"
                  )}
                />
                <div className="pt5_5 pt5-ns">
                  <ReturnToTop targetId="quick-navigation" />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        <div>
          <Container>
            <Row className="relative">
              <Box className="layout-readable">
                <Heading size={2}>
                  We want to hear what you have to say.
                </Heading>
                <p>
                  We welcome feedback and/or questions from the people we
                  support, their families, carer, other agencies, and the
                  public.
                </p>
                <Heading size={3}>
                  How do I leave feedback or make a complaint?
                </Heading>
                <p>Get in touch with our services team.</p>
                <div className="pl1 pt3-ns mv4 mb5-l flex flex-column flex-row-l">
                  <div className="w-40-l">
                    <div className="flex items-center">
                      <IconWrapper
                        size="30"
                        color={corporateThemeFull.xxxlight}
                      >
                        <IconPhone
                          height="20"
                          width="20"
                          color={corporateThemeFull.dark}
                        />
                      </IconWrapper>
                      <div className="pl3">
                        <Link
                          className="black fw3 no-underline-ns f3-ns fw8-ns"
                          to="tel:1800935483"
                          ariaLabel="Phone 1 8 0 0. 9 3 5. 4 8 3"
                        >
                          1800 935 483
                        </Link>
                      </div>
                    </div>
                    <div className="mt2 flex items-center">
                      <IconWrapper
                        size="30"
                        color={corporateThemeFull.xxxlight}
                      >
                        <IconAt
                          height="20"
                          width="20"
                          color={corporateThemeFull.dark}
                        />
                      </IconWrapper>
                      <div className="pl3">
                        <a
                          className="black fw4"
                          href="mailto:yourLWB@lwb.org.au"
                        >
                          yourLWB@lwb.org.au
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex pt4 pv4-ns pv0-l pl5-l">
                    <IconWrapper size="30" color={corporateThemeFull.xxxlight}>
                      <IconClock
                        height="20"
                        width="20"
                        color={corporateThemeFull.dark}
                      />
                    </IconWrapper>
                    <div className="pl3">
                      <p className="mt0 fw8">Opening hours (AEST)</p>
                      <p className="mt1">
                        <SpacedSpan>Mon - Fri</SpacedSpan>
                        8:30AM - 5PM
                      </p>
                      <p className="mt0">
                        <SpacedSpan>Sat - Sun</SpacedSpan>
                        Closed
                      </p>
                    </div>
                  </div>
                </div>
                <ReadMoreLink
                  href="/contact-us/complaints-or-feedback/"
                  title="Feedback and complaints"
                  text="Feedback and complaints"
                  rooted={true}
                />
                <div className="mt4 copy">
                  <p className="pb2">
                    If you have hearing or speech impairment National Relay
                    Service can help you with your call.
                  </p>
                </div>
                <div className="mt3 copy">
                  <div className="pb2">
                    <div>
                      <span className="fw4 pr1">Voice Relay</span>
                      <Link
                        className="black fw8 link"
                        to="tel:1300555727"
                        ariaLabel="Phone number 1 3 0 0. 5 5 5. 7 2 7"
                        rooted
                      >
                        1300 555 727
                      </Link>
                    </div>
                    <div>
                      <span className="fw4 pr1">TTY</span>
                      <Link
                        className="black fw8 link"
                        to="tel:133677"
                        ariaLabel="Phone number 1 3. 3 6. 7 7"
                        rooted
                      >
                        133677
                      </Link>
                    </div>
                    <div>
                      <span className="fw4 pr1">SMS relay number</span>
                      <Link
                        className="black fw8 link"
                        to="tel:0423677767"
                        ariaLabel="Phone number 0 4 2 3. 6 7 7. 7 6 7"
                        rooted
                      >
                        0423 677 767
                      </Link>
                    </div>
                  </div>
                </div>
              </Box>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    policyDocuments: allContentfulPolicyDocumentV2 {
      edges {
        node {
          title
          category
          policyDocument {
            filename
            title
            url
          }
        }
      }
    }
  }
`

export default PolicyCentrePage
